@import "@/shared/variables";

.container {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    font-weight: bold;
}

.container>div:nth-child(1n)>a {
    color: #002554;
    text-decoration: none;
    font-size: 13px;
}


.selected>a {
    color: $color-blue-dark !important;
    font-weight: bold;
    font-size: 13px;
}


.separator {
    margin-left: 5px;
    margin-right: 5px;
}