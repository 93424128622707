@import "@/shared/variables";

.option-compra{
    border-bottom: 1px solid #D8D8D8;
  }

  .option-compra > div {
    padding: 10px 20px;
  }

  .option-compra > div > span {
    font-size: 0.9rem;
    color: #002554;
  }

  .option-compra > div > p {
    color: #002554;
    font-weight: bold;
    margin: 15px 0;

    cursor: pointer;
  }

  .text-compra-rapida {
    color: $color-blue-dark;
    font-size: 16px;
    font-weight: bold;
    margin: 0;

    @media screen and (min-width:768px) {
        font-size: 24px;
    }
  }

  .container-headers{
    grid-area: header;
    border-bottom: 1px solid $color-gray-light;
  }

  .header{
    height: 100%;
    border-bottom: 1px solid #D8D8D8;
  }
  .header > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 100%;
  }

  .text-compra-rapida-icon{
    color: $color-blue-dark;
    font-size: 1.9rem;
    font-weight: bold;
    margin: 0;
  }

  .container-body{
    grid-area: body;

    @media screen and (max-width:768px) {
      padding: 20px;
    }
  }

  .container-close-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
  }

  .container-sku {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .search-input {
    width: 100%;
    border: $border $color-gray-light;
    border-radius: $border-radius;
  }

  .container-buttons {
    display: flex;
    justify-content: center;
    padding-top: 21px;

    @media screen and (min-width:768px) {
        justify-content: space-between;
        padding-top: 20px;

    }

  }

  .text-entry{
    color: #002254;
    font-size: 12px;
    margin: 20px 0;

    @media screen and (min-width:768px) {
        font-size: 1rem;
    }
  }

  .content-text {
    color: $color-blue-dark;
    font-size: 15px;
    font-weight: bold;
  }

  .container-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: $border $color-gray-light;
    padding: 50px;
    border-radius: 10px;
    cursor: pointer;
  }

  .container-upload input{
    display: none;
  }

  .text-upload {
    text-align: center;
    color: $color-blue-dark;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
  }

  .span-upload {
    font-weight: normal;
  }

  .text-download{
    display: flex;
    align-items: center; 
    flex-direction: column;
    font-size: 12px;
    margin-top: 40px;
    gap: 7px;
    p {
      cursor: default;
    }

    @media screen and (min-width:768px) {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 33px 0;
        color: #424242;
    }
  }

  .container-body-description {
    @media screen and (min-width:768px) {
        width: 70%;
        margin: 20px auto
    }
  }

.container-body-upload {
    margin-top: 20px;

    @media screen and (min-width:768px) {
        width: 85%;
        margin: 20px auto;
        text-align: center;
    }
}

.cancel-button {
    @media screen and (max-width:768px) {
      display: none;
    }
}

.upload-button {
    width: 206px;
    height: 26px;
    font-size: 12px;

    @media screen and (min-width:768px) {
        width: 170px;
        height: 30px;
        font-size: 14px;
    }
}

.text-download p {
    margin: 0;
    font-size: 12px;
    color: #424242;
    font-size: 12px;

    @media screen and (min-width:768px) {
        font-size: 14px;
    }

}

.text-download a {
    cursor: pointer;
    color: #165EEB;
    display: flex;
    font-size: 12px;
    column-gap: 5px;

    @media screen and (min-width:768px) {
        font-size: 14px;
    }
}

.cancel-button {
    @media screen and (min-width:768px) {
        width: 170px;
        height: 30px;
        font-size: 14px;
    }
}
