@import "@/shared/variables";

.header {
    display: flex;
    flex-flow: row nowrap;
    color: $color-blue-dark;
    margin-top: 30px;
    font-weight: bold;
    padding-right: 15px;

}

.header>div:first-child {
    // 30px due icon width
    flex-basis: calc(100% - 30px);
    user-select: none;
}


// Notificaciones
.header>div:first-child>div:first-child {
    font-size: 0.83rem;
    padding-top: 5px;
}

.header>div:first-child>div:nth-child(2) {
    font-size: 0.75rem;
}

//icon
.header>div:nth-child(2)>div:first-child {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.notification-cards {
    height: calc(100% - 133px);
    width: 95%;
    margin: 22px auto 0 auto;
    padding-right: 2px;
    overflow-y: scroll;
}

.card {
    display: flex;
    flex-flow: column nowrap;
}

.card:not(:last-child) {
    border-bottom: 1px solid $color-gray-dark;
}

.card:not(:first-child) {
    padding-top: 20px;
}

.card>div:first-child {
    display: flex;
    flex-flow: row nowrap;
    font-size: 1rem;
    font-weight: bold;
    color: $color-blue-dark;
}

.card>div:last-child {
    color: $color-black;
    font-size: 0.8rem;
    margin-top: 10px;
    padding-bottom: 18px;
}

.icon-container {
    display: flex;
    margin-left: auto;
}

.icon-container>div:first-child {
    border-radius: 50%;
    border: 2px solid blue;
    width: 10px;
    height: 10px;
}