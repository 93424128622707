@import "@/shared/variables";

.option-compra{
    border-bottom: 1px solid #D8D8D8;
  }

  .option-compra > div {
    padding: 10px 20px;
  }

  .option-compra > div > span {
    font-size: 0.9rem;
    color: #002554;
  }

  .fastbuysku-input{
    margin-top: 10px;
  }

  .option-compra > div > p {
    color: #002554;
    font-weight: bold;
    margin: 15px 0;

    cursor: pointer;
  }

  .container-headers{
    grid-area: header;
    border-bottom: 1px solid $color-gray-light;
  }

  .header{
    height: 100%;
    border-bottom: 1px solid #D8D8D8;
  }
  .header > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 100%;
  }

  .text-compra-rapida {
    color: $color-blue-dark;
    font-size: 16px;
    font-weight: bold;
    margin: 0;

    @media screen and (min-width:768px) {
        font-size: 24px;
    }
  }

  .text-compra-rapida-icon{
    color: $color-blue-dark;
    font-size: 1.9rem;
    font-weight: bold;
    margin: 0;
  }

  .container-body{
    grid-area: body;
  }

  .container-close-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
  }

  .container-sku {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .search-input {
    width: 100%;
    border: $border $color-gray-light;
    border-radius: $border-radius;

    @media screen and (max-width:768px) {
        height: 33px;
    }
  }

  .search-input::placeholder {
    @media screen and (max-width:768px) {
      font-size: 12px;
      color: #4F4F4F;
    }
  }

  .container-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 21px;
  }

  .text-entry{
    color: #002254;
    font-size: 12px;
    margin: 20px 0;

    @media screen and (min-width:768px) {
        font-size: 14px;
    }
  }

  .content-text {
    color: $color-blue-dark;
    font-size: 12px;
    font-weight: bold;

    @media screen and (min-width:768px) {
        font-size: 15px;
    }
  }

  .container-sku select {
    width: 100% !important;
  }

  .container-sku div {
    margin-top: 0;
  }


  .sku-body-container {
  padding: 20px;

    @media screen and (min-width:768px) {
        width: 70%;
        margin: 20px auto;
        padding: 0;
    }
  }


.count-button {
    width: 114px;
    height: 30px;

    @media screen and (min-width:768px) {
        width: 150px;
        height: 30px;
    }
}

.cart-button {
    width: 170px;
    height: 30px;

    @media screen and (min-width:768px) {
        width: 150px;
        height: 30px;
    }
}
