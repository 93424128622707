@import "@/shared/variables";

.header {
     width: 100%;
     max-width: auto;
     min-width: 0;
     border-bottom: 1px solid $color-gray-light;
     padding: 15px;
     box-sizing: border-box;
     display: flex;
     flex-flow: row nowrap;
     align-items: center;
 }

.body {
  width: 100%;
  height: 100%;
  min-width: 0;
  padding: 15px;
  box-sizing: border-box;
  min-height: 11px;

  @media screen and (min-width: 758px) {
    min-height: 184px;
  }
}

   .body p {
    color: $color-black;
    font-size: 12px;
    margin: 0;

    @media screen and (min-width: 758px) {
      font-size: 14px;
    }
   }

   .body h1 {
    color: $color-blue-dark;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 13px;

    @media screen and (min-width: 758px) {
      font-size: 18px;
    }
   }

   .titulo {
    color: $color-blue-dark;
    font-size: 18px;
    font-weight: bold;
    margin: 0;

    @media screen and (min-width: 758px) {
        font-size: 20px;
      }
    }


   .subtitulo {
    color: $color-blue-dark;
    font-size: 18px;
    font-weight: bold;
   }

   .icon{
    margin-left: auto;
   }

   .container {
    border: 1px solid $color-gray-light;
    border-radius: 5px;
   }


   .unset-height{
    height: unset;
  }