@import "@/shared/variables";


.container-compra-rapida {
  position: relative;
  min-width: 250px;
  max-width: 550px;
  height: 100%;
}

.contain-loader {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(31, 30, 30, 0.233);
  z-index: 20;
}


.hideLoader {
  display: none;
}

.loader {
  display: block;
  border: 8px solid #00000062;
  border-top: 8px solid #165EEB;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  animation: rotateSplash 2s infinite;
}

@keyframes rotateSplash {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


.containter-button {
  padding-left: 100px;
  // width: 100%;
}

.button-compras-rapidas {
  width: 175px;
  // height: 30px;
  background-color: #002554;
  color: white;
  border-radius: 20px 20px 20px 20px;
  padding: 5px 28.59px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.container-draw {
  height: 100%;
  display: grid;
  grid-template-areas:
    "header"
    "body"
    "footer"
  ;
  grid-template-rows: 69px 1fr 156px;
  width: 100%;
  transition: height 3s ease-in-out;

  @media screen and (min-width: 1025px) {
    grid-template-rows: 100px 1fr 210px;
}
}

.container-resumen {
  height: 100%;
  display: grid;
  grid-template-areas:
    "header"
    "footer"
  ;
  grid-template-rows: 69px 1fr;
  transition: height 3s ease-in-out;

  @media screen and (min-width: 1025px) {
    grid-template-rows: 100px 1fr;
}
}

.header {
  height: 100%;
  border-bottom: 1px solid #D8D8D8;
}

.header>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 100%;
}

.content-button {
  display: flex;
  justify-content: space-between;

  .content-desk {
    display: flex;
  }

  .content-mob {
    display: none;
  }

}

.icon-button {
  margin-right: 2px;
  padding-top: 2px;
}

// Content Drawer
.container-headers {
  grid-area: header;
  border-bottom: 1px solid $color-gray-light;
}

.container-body {
  grid-area: body;
  width: 500px;
}

.option-compra {
  border-bottom: 1px solid #D8D8D8;
}

.option-compra>div {
  padding: 10px 20px;
}

.option-compra>div>span {
  font-size: 0.9rem;
  color: #002554;
}

.option-compra>div>p {
  color: #002554;
  font-weight: bold;
  margin: 15px 0;

  cursor: pointer;
}


.container-footer {
  grid-area: footer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 20px;
  border-top: 1px solid #D8D8D8;
}

.container-footer-resumen {
  grid-area: footer;
  display: grid;
  grid-template-rows: auto 1fr 100px;
  padding: 0 20px;
  border-top: 1px solid #D8D8D8;
}

.contain-flex>h3 {
  color: #002554;
  font-weight: bold;
  margin: 0;
  font-size: 16px;

  @media screen and (min-width: 1025px) {
    font-size: 1.3rem;
}
}

.contain-flex>p {
  margin: 0;
  font-size: 14px;
}

.container-header {
  grid-area: header;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 4px;
}

.container-header {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.text-compra-rapida {
  color: $color-blue-dark;
  font-size: 16px;
  font-weight: bold;
  margin: 0;

  @media screen and (min-width: 1025px) {
    font-size: 24px;
}
}

.container-close-icon {
  margin-top: 12px;
}

.under-line {
  border-bottom: $border $color-gray-light;
  margin-bottom: 10px;
}

.container-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.search-sku {
  margin-bottom: 21px;
}

// .updload-file {
//   margin-top: 80px;
// }

// Upload

.container-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: $border $color-gray-light;
  padding: 50px;
}

.text-upload {
  text-align: center;
  color: $color-blue-dark;
  font-size: 14px;
  font-weight: bold;
}

.span-upload {
  font-weight: normal;
}

.container-buttons {
  display: flex;
  justify-content: space-around;
}


.contain-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow {
  cursor: pointer;
}

.arrow-open svg{
  transform: rotate(180deg);
}

.button1 {
  padding: 15px 40px;
  border: 1px solid #165EEB;
  color: #165EEB;
  background-color: white;
  border-radius: 30px;
  text-decoration: none;
  width: 200px;
  height: 40px;
}

.button2 {
  padding: 15px 40px;
  border: 1px solid #165EEB;
  color: white;
  background-color: #165EEB;
  border-radius: 30px;
  text-decoration: none;
  width: 200px;
  height: 40px;
}

.shopping-cart-table {}

.contain-flex-button,
.contain-flex-button2 {
  width: 142px;
  height: 36px;

  @media screen and (min-width:1025px) {
    width: 200px;
    height: 40px;
}
}


.contain-flex-button>button>a {
  text-decoration: none;
}

.contain-flex-button2>button>a {
  text-decoration: none;
  color: #FFFFFF;
}

@media (max-width: 1024px) {
  .containter-button {
    padding: 0;
  }

  .button-compras-rapidas {
    width: fit-content;
    padding: 5px;
    background-color: transparent;

    .content-button {
      .content-desk {
        display: none;
      }

      .content-mob {
        display: flex;

        svg {
          width: 23px;
          height: 19px;
        }
      }
    }
  }


}

.contain-flex-total {
    font-size: 12px;

    @media screen and (min-width: 1025px) {
        font-size: 1rem;
    }
}
