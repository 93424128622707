@import '@/shared/variables';


.select-container{
  overflow: hidden;
  position: relative;
}

.select-container:after{
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #165EEB;
  position: absolute;
  top: 40%;
  right: 5px;
  content: "";
  z-index: 98;
 }
.select-container select{
  position: relative;
  z-index: 99;
  background: none;
}

.container {
  width: 100%;
  height: 100%;
  border: 1.2px solid $color_blue_light_2;
  border-radius: 18px;

  font-size: 12px;
  padding: 7px 15px;
}

.delete-icon {
  position: absolute;
  right: 11px;
  top: 33%;
}

.container-background {
  background: url(../../../../../../public/arrowDown.png);
  background-repeat: no-repeat;
  background-position: 90% center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.container-icon {
  background: $color_white;
}

.container::-ms-expand {
  display: none;
}
@media (max-width: 550px) {
  .container {
    width: 100%;
    
  }
}