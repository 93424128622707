@import "@/shared/variables";

.option-compra{
    border-bottom: 1px solid #D8D8D8;
  }

  .option-compra > div {
    padding: 20px 30px;

    @media screen and (min-width:768px) {
        padding: 20px 40px;
    }
  }

  .option-compra > div > span {
    font-size: 12px;
    color: #002554;

    @media screen and (min-width:768px) {
        font-size: 0.9rem;
    }
  }

  .option-compra > div > p {
    color: #002554;
    font-weight: bold;

    cursor: pointer;
  }

  .container-headers{
    grid-area: header;
    border-bottom: 1px solid $color-gray-light;
  }

  .header{
    height: 100%;
    border-bottom: 1px solid #D8D8D8;
  }
  .header > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 100%;
  }

  .text-compra-rapida {
    color: $color-blue-dark;
    font-size: 18px;
    font-weight: bold;
    margin: 0;

    @media screen and (min-width:768px) {
        font-size: 24px;
    }
  }

  .container-body{
    grid-area: body;
  }

  .option-compra div{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }



