div:has(> .skeleton) {
    padding: 0;
    border: none;
}

.text {
    background-color: #D8D8D8;
    filter: opacity(0.7);
    width: 100%;
    height: 20px;
}

.title {
    background-color: #D8D8D8;
    filter: opacity(0.7);
    width: 200px;
    height: 45px;
}

.card {
    background-color: #D8D8D8;
    filter: opacity(0.4);
    width: 100%;
    height: 100%;
    border-radius: 15px;
    min-width: 210px;
    min-height: 210px;
}

.icon {
    
}

.dropdown{
    background-color: #D8D8D8;
    filter: opacity(0.4);
    width: 100%;
    height: 100%;
    border-radius: 40px;
}

.button {

}

.search {

}