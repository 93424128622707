@import '@/shared/variables';

.active {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: $color_blue_light;
  color: $color-white;
}

.inactive {
  color: $color-blue-dark;
}

.legend {
  color: $color-black;
}

.grid-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.content-paginator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 20px;
  align-items: center;
}

.page {
  font-weight: bold;
  font-size: 13px;
}
