@import "@/shared/variables";

.container {
    display: flex;
    flex-flow: column nowrap;
}

.container>div:not(:first-child) {
    height: 25px;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

}

.notification-container {
    margin: auto;
    // 185px - 15px of radio from notification icon
    height: 170px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.notification-container>div {
    cursor: pointer;
}

.list {
    padding-left: 0px;
    margin: 0px;
}

.list>li {
    padding: 13px;
    cursor: pointer;
    transition: 0.2s ease-in-out;

}
//removing Link styles
.list>li>a {
    text-decoration: none;
    color: black;
}

.list>li:hover {
    background-color: $color-gray-light;
}

.list>li:active {
    background-color: $color-gray-dark;
}

.log-out-container {
    margin-top: auto;
    margin-bottom: 70px;
  }

  .log-out-container svg{
    width: 15px; /* Ancho del icono */
    height: 15px; /* Altura del icono */
  }

.seleccionado {
    border-radius: 13px;
    background: $color_blue_light;
    padding: 5px 10px;
    width: 35px;
    display: flex;
    height: 26px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}