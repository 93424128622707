@import '@/shared/variables';

.select-container select {
  background: url(../../../../../../public/arrowDown.png);
  border: 1px solid $color_blue_light;
  border-radius: 19px;
  width: 50%;
  color: $color_blue_light;
  font-size: 12px;
  font-weight: bold;
  background-repeat: no-repeat;
  background-position: 90% center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  min-width: 156px;
}

.select-container select::-ms-expand {
  display: none;
}

.select-container {
  display: flex;
  justify-content: space-between;
  row-gap: 20px;
  margin-top: 20px;
}

.select-container div {
  display: flex;
  justify-content: space-between;
}

.select-container label {
  // margin-right: 16px;
  margin-right: 10px;
  color: #002554;
  font-size: 12px;
  white-space: nowrap;
  font-weight: bold;
}

.select-container option {
  padding: 5px;
}

@media screen and (min-width: 758px) {
  .select-container {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .select-container select {
    width: 100%;
  }

  .select-container input {
    width: calc(100% - 70%);
  }

  .select-container div {
    display: block;
  }
}

@media (max-width: 576px) {
  .select-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .select-container select {
    width: 100%;
  }
  
  .select-container label {
    margin-top: 10px;
  }
}


.input-on-error,.input-on-error:focus,.input-on-error:active{
  border: 1px solid $color-red !important;
  color: $color-red-light !important;
}