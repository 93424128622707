@import "@/shared/variables";

.side {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.side .logo {
  // text-align: center;
  border-bottom: 1px solid $color-gray-light;
  padding: 20px;
}

.side .icon-notifications {
  margin-top: 26px;
  margin-bottom: 10px;
  width: 30px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .side .logo a {
    display: flex;
    justify-content: center;
    margin: 0 auto 35px;
    width: fit-content;
  }

  .side .icon-notifications {
    display: none;
  }
}

.side .full-name {
  font-weight: 700;
  margin-top: 10px;
  font-size: 14px;
  color: $color-blue-dark;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.side .rol {
  color: #424242;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.side>ul {
  list-style: none;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
  font-weight: 600;
}

.side>ul li span {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  font-weight: 600;
}

.side>ul li a {
  text-decoration: none;
  color: #002554;
}

.menu-item-dropdown,
.menu-item {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: white;
  color: $color-blue-dark;
  font-weight: 600;
  font-size: 14px;
  border-radius: 20px;
  margin-bottom: 7px;
  padding: 4px 12px;
  cursor: pointer;

  &.active {
    background-color: $color_blue_light_2 ;
    color: white;

    svg path {
      fill: #ffffff;
    }
  }

  &.open {
    background-color: $color_blue_light_2 ;
    color: white;

    .dropdown-arrow {
      transform: rotate(180deg);
    }

    svg path {
      fill: #ffffff;
    }

    +.sections-container {
      max-height: 300px;
      transition: .15s ease;
      padding-bottom: 15px;
    }
  }

  &:hover {
    background-color: $color_blue_light_2;
    color: white;

    svg path {
      fill: #ffffff;
    }
  }

  span {
    margin-bottom: 0;
  }

  .dropdown-arrow {
    margin-left: auto;
  }
}

.sections-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  max-height: 0;
  overflow: hidden;
  transition: .15s ease;


  li:hover a {
    color: #165EEB;
  }

  li.active a {
    color: #165EEB;
    font-weight: 600;
  }
}

.container-icon-menu {
  display: flex;
  align-items: center;
}

.custom-accordion button .icon svg {
  margin-right: 5px !important;
  width: 15px;
  height: 15px;
}


// Log out 

.log-out-container {
  display: flex;
  align-items: center;
  // justify-content: center;
  margin-left: 18px;
  margin-top: 60px;
  cursor: pointer;
}

.log-out-icon {
  margin-right: 5px;
}

.log-out-text {
  color: $color-blue-dark;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.icon-side {
  margin-left: auto;
}