@import "@/shared/variables";

.option-compra {
  border-bottom: 1px solid #D8D8D8;
}

.option-compra>div {
  padding: 10px 20px;
}

.option-compra>div>span {
  font-size: 0.9rem;
  color: #002554;
}

.option-compra>div>p {
  color: #002554;
  font-weight: bold;
  margin: 15px 0;

  cursor: pointer;
}

.container-headers {
  grid-area: header;
  border-bottom: 1px solid $color-gray-light;
}

.header {
  height: 100%;
  border-bottom: 1px solid #D8D8D8;
}

.header>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 100%;
}

.text-compra-rapida {
  color: $color-blue-dark;
  font-size: 18px;
  font-weight: bold;
  margin: 0;

  @media screen and (min-width:768px) {
    font-size: 24px;
}
}

.text-compra-rapida-icon {
  color: $color-blue-dark;
  font-size: 1.9rem;
  font-weight: bold;
  margin: 0;
}

.container-body {
  grid-area: body;
  padding: 20px;

  @media screen and (min-width:768px) {
    padding: 20px 0;
    width: 70%;
    margin: 0 auto;
}
}

.container-close-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}

.container-sku {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.search-input {
  width: 100%;
  border: $border $color-gray-light;
  border-radius: $border-radius;
}

.container-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 21px;
}

.text-entry {
  color: #002254;
  font-size: 12px;
  margin: 0;

  @media screen and (min-width:768px) {
    font-size: 14px;
    margin: 20px 0;
}

}

.content-text {
  color: $color-blue-dark;
  font-size: 12px;
  font-weight: bold;

  @media screen and (min-width:768px) {
    font-size: 14px;
}


}

.container-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: $border $color-gray-light;
  padding: 50px;
  border-radius: 10px;
}

.text-upload {
  text-align: center;
  color: $color-blue-dark;
  font-size: 14px;
  font-weight: bold;
}

.span-upload {
  font-weight: normal;
}

.container-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 21px;
}

.text-download {
  display: block;
  text-align: center;
  margin: 20px 0;
  color: #424242;
}

.button {
    width: 206px;
    height: 30px;
    margin: 0 auto;

    @media screen and (min-width:768px) {
        width: 170px;
        height: 30px;
    }
}

.button button {
        font-weight: bold;
        font-size: 12px;
}

.container-sku select{
    width:100%;
}

.container-sku div{
    @media screen and (max-width:768px) {
        margin-top: 0;
    }
}
