@import '@/shared/variables';

$gap: 10px;

.data-container {
  display: flex;
  flex-wrap: nowrap;
  // padding-right: 7vw;
  margin-bottom: 30px;
  gap: $gap;
}

.data-item {
  font-weight: bold;
  flex-basis: 25%;
  color: #002554;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.data-item > label:last-child {
  color: #001228;
}

.item-value {
  padding-left: 17px;
}

.actions-container {
  text-align: end;
}

.actions-container button {
  background-color: #ffffff;
  color: #165eeb;
  border: 1px solid #165eeb;
  border-radius: 20px;
  padding: 7px 13px;
  font-size: 14px;
  font-weight: bold;
}

.actions-container button:hover {
  color: #0e4fcd;
  border: 1px solid #0e4fcd;
}

.invalid {
  border: 1px solid $color-red !important;
  font-size: 12px;
}

.invalid::placeholder {
  color: $color-red !important;
  font-size: 12px;
}

.form-container > div > label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-size: 14px;
  color: $color-blue-dark;
  font-weight: 500;
  font-family: 'Nunito Sans', sans-serif;
}

.form-container > span {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-size: 10px;
  color: #4f4f4f;
  font-family: 'Nunito Sans', sans-serif;

  display: inline-block;
  line-height: 11px;
  letter-spacing: 0px;
  margin-top: 5px;
}

.form-container > div > div > input {
  border: 1px solid #f1f2f4;
  border-radius: 37px;
  margin: 10px 0px 20px 0px;
}

.form-container > div > div > input::placeholder {
  font-size: 0.9rem;
  color: #4f4f4f;
}

@media (max-width: 550px) {
  .data-container {
    flex-wrap: wrap;
    padding-right: 0;
    gap: 20px;
  }

  .data-item {
    flex-basis: calc(50% - $gap);
    gap: 10px;
  }
}
