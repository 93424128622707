@import '@/shared/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 55px 80px;
  width: 100%;
}

.breadcrumb-container {
  margin-right: 10px;
  width: 100%;
  max-width: 337px;
}

.header input {
  justify-self: center;
}

.cart {
  position: relative;
  cursor: pointer;
  margin-left: 39px;
  margin-right: 10px;
}

.cart span {
  position: absolute;
  display: grid;
  place-items: center;
  width: 22px;
  height: 22px;
  background-color: #ff6060 !important;
  border-radius: 50%;
  font-size: 10px;
  bottom: 0px;
  right: -12px;
  color: #fff !important;
}

.search-input-container {
  position: relative;
  width: 100%;
  margin-left: 10px;
}

.search-input {
  border: $border $color-gray-light;
  border-radius: $border-radius;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 30%;
  right: 10px;
  pointer-events: none;
}

.hamburger-menu {
  display: none;
}

.logo {
  display: none;
}

.notification {
  display: none;
}

.compra-rapida {
  display: block;
  margin-left: auto;
}

.header-breadcrumb {
  display: block;
  margin: 20px 0 0 25px;

  @media screen and (min-width: 1302px) {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .header-breadcrumb {
    margin: -68px 0 0 25px;
  }
}
@media screen and (min-width: 200px) {
  .breadcrumb-container {
    display: none;
  }
}
@media screen and (min-width: 1302px) {
  .breadcrumb-container {
    display: block;
  }
}

@media (max-width: 1024px) {
  .search-input-container{
    width: 90%;
    margin: 0 auto !important;
  }
  .logo {
    display: block;
    grid-area: logo;
  }

  .hamburger-menu {
    display: block;
    grid-area: hamburger-menu;
  }

  .notification {
    display: block;
    grid-area: notification;
    margin-left: auto;
  }

  .notification {
    display: none;
  }

  .compra-rapida {
    grid-area: fast;
    margin: 0;
  }

  .cart {
    grid-area: cart;
    margin: 0px;

    svg {
      width: 20px;
      height: 20px;
    }

    span {
      position: absolute;
      display: grid;
      place-items: center;
      width: 15px;
      height: 15px;
      background-color: #ff6060 !important;
      border-radius: 50%;
      font-size: 7px;
      bottom: -2px;
      right: 13px;
      color: #fff !important;
    }
  }

  .search-input-container {
    grid-area: search-input-container;
    margin: 0;

    input {
      padding: 11px 15px;
    }

    .search-icon {
      right: 20px;
    }
  }

  .header {
    row-gap: 10px;
    column-gap: 20px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 30px 65px 1fr 30px 40px;
    grid-template-areas: 'hamburger-menu logo  search-input-container fast cart';
    padding: 15px 25px;
    border: 1px solid #d8d8d8;
  }
}

@media screen and (max-width: 768px) {
  .header {
    grid-template-columns: 30px 1fr 30px 40px;
    grid-template-areas:
      'hamburger-menu logo fast cart'
      'search-input-container search-input-container search-input-container search-input-container';
  }
}
